@import "./products";

.header-right-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  text-align: center;
  margin-right: 0px;
  max-width: 200px;
  margin-left: 30px;

  @include respond-down(small){
    display: none;
  }
}

.header-search-form {
  form {
    input[type="text"] {
      height: 36px;
      background: transparent;
      border-radius: 0;
      border-bottom: 2px solid #F5B142;
      padding: 0 20px 0 0;
      width: 100%;
    }
  }
}
