.index-block.products {
  overflow: hidden;
  @include respond-down(middle) {
  }
  @include respond-down(small) {
  }
}

.products_wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-content: space-between;

  @include respond-down(small){
    display: block;
  }
}

.products__items {
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(auto-fit, minmax(335px, 400px));
  justify-content: center;
}

.products__item {
  @include respond-down(medium) {
    margin-bottom: 20px;
  }

  &:hover {
    .products__image {
      transform: scale(1.1);
    }
  }
}

.products__image-wrapper {
  background: $white;
  display: flex;
  height: 286px;
  transition: 1s;
  overflow: hidden;
  @include respond-down(middle) {
    height: 275px;
  }
  @include respond-down(small) {
    height: 250px;
  }
}

.products__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.products__title-block {
  background: $white;
  padding: 0 20px 30px 20px;

  @include respond-down(small) {
    padding: 15px 15px 20px 20px;
  }

}

.products__title {
  font-size: 16px;
  margin-bottom: 7px;
  padding: 20px 0px 0 0;
  height: 65px;
  display: flex;
  justify-content: space-between;
  .title{
    @include textBold();
    color: $black;
    text-transform: uppercase;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }



  @include respond-down(middle) {
    margin: 24px 0 0px 0;
  }

  @include respond-down(small) {
    margin: 0px;
    margin-bottom: 10px;
    max-height: 65px;
    height: auto;
    padding-top: 0px;
  }
}

.products__button {
  width: 80%;
  height: 52px;
  line-height: 52px;
  font-size: 15px;
  display: block !important;
  margin: 0 auto !important;
}

.products__read-more {
  @include textBold();
  text-transform: uppercase;
  padding: 0 0 1px 0;
  border-bottom: 1px solid $blue;
  line-height: calc(18 / 14);
  transition: all ease-out .2s;
  display: inline-block;

  &:hover {
    border-bottom-color: $t;
  }
}