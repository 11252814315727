.products-list-page {

  margin-bottom: 30px;

  .page-title {
    @include respond-property(font-size, 20px, 20px, 25px, 30px, 30px, 30px);
    margin-bottom: 40px;
    font-weight: $bold;
    line-height: calc(37 / 30);
    color: $black;

  }

  .page-content {

    .products__items {
      .products__item {
        a:not(.products__read-more){
          display: block;
          width: 100%;
        }
        @include respond(small) {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .products__read-more {
          display: inline-block;
        }
      }
    }

    .pagination-wrapper {
      margin: 0;
      @include respond-down(medium) {
        margin: 30px 0 0;
      }

      .pagination-block {
        .pagination-list {
          li {
            text-align: center;

            a {
              background: transparent;
              color: $orange;
              &.loading{
                .svg-icon{
                  animation-name: spin;
                  animation-duration: 1000ms;
                  animation-iteration-count: infinite;
                  animation-timing-function: cubic-bezier(0.950, 0.050, 0.795, 0.035);
                }

              }
              .svg-icon {
                margin-right: 15px;
              }

              span {
                @include textUnderline;
                text-transform: uppercase;
                font-weight: $bold;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
